<template>
  <v-parallax
    class="text-center pt-4 info parallax"
    height="400"
    :src="require('@/assets/hero/blog-is-about-people.svg')"
  >
    <v-row align="center" style="max-width:1920px;" class="mx-auto">
      <v-col>
        <h1 class="center bold shadow mb-6 mb-md-8">{{ $t('home.punchline') }}</h1>

        <!-- <signup-button
          class="mb-6 mb-md-8"
          color="white"
          :label-text="$t('pricing.free_trial')"
        /> -->

        <h3 class="center bold ">{{ $t('home.platform') }}</h3>

        <div class="mt-6 mt-md-4">
          <c-nav-button outlined color="white" to="/pricing">
            {{ $t('pricing.label') }}
          </c-nav-button>
        </div>

      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>

// import SignupButton from '@/components/_lib/templates/SignupButton'

export default {
  components: {
    // SignupButton
  }
}
</script>

<style lang="scss" scoped>
.parallax {
  // set background color so that we can see div when page is loading, avoid white flashing
  background-color: $bligson-info;
}
</style>
