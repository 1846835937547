<!-- Show a number of blogpost next to each other or under each other. In carousel on a mobile

 <whats-new :skip="2" vertical compact />

  -->

<template>
  <c-chapter id="what-is-new">
    <template v-if="!compact">
      <c-spacer half />
      <h2 class="center my-4">{{ $t('home.what_is_new.title') }}</h2>
      <h3 class="center mb-4">{{ $t('home.what_is_new.subtitle') }}</h3>
    </template>

    <fetch-bligman v-model="posts" posts :api-filter="`&$limit=${limit}&$skip=${skip}`" />

    <template v-if="posts.length>0">

      <!-- show NR_POSTS posts at same time on large screen -->
      <v-row class="d-none d-md-flex mt-2 mb-2 ">
        <v-col
          v-for="(post,i) in posts"
          :key="i"
          :cols="vertical ? 12 : 12 / posts.length"
        >
          <blog-card-post :post="post" :compact="compact" />
        </v-col>
      </v-row>

      <!-- show carousel with limit of NR_POSTS posts on smaller screens -->
      <v-carousel
        class="post-carousel d-md-none"
        light
        :show-arrows="false"
        hide-delimiter-background
        :height="compact ? 270:550"
      >
        <v-carousel-item v-for="(post,i) in posts" :key="i">
          <blog-card-post :post="post" :compact="compact" />
        </v-carousel-item>
      </v-carousel>
    </template>

    <div class="d-flex justify-center">
      <v-btn class="pb-2" color="primary" to="/insights" text>{{ $t("def.more") }}</v-btn>
    </div>
    <c-spacer half />
  </c-chapter>
</template>

<script>

import BlogCardPost from '@/components/blog/BlogCardPost.vue'
import FetchBligman from '@/components/_lib/FetchBligman.vue'

export default {
  components: {
    BlogCardPost,
    FetchBligman
  },
  props: {
    limit: {
      type: Number,
      default: 3
    },
    skip: {
      type: Number,
      default: 0
    },
    vertical: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      posts: []
    }
  }

}
</script>
