<template>
  <div>
    <c-chapter
      id="employee-blog-reasons"
      :title="$t('home.problem.title')"
      title-center
    >
      <!-- :img-src="require('@/assets/silhouettes/pages/thinker-look-right.svg')"
      img-alt="Employer Branding Problems"
      img-left -->

      <!-- *** The Problem -->
      <v-row>
        <v-col>
          <i18n path="home.problem.description" tag="p">
            <template v-slot:industries_link>
              <c-link to="/industry">{{ $t('home.problem.industries_link.tag') }}</c-link>
            </template>

            <template v-slot:wiki_link>
              <c-link :href="$t('home.problem.wiki_link.url')" target="_blank">{{ $t('home.problem.wiki_link.tag') }}</c-link>
            </template>

            <template v-slot:appreciation_link>
              <c-link :href="$t('home.problem.appreciation_link.url')" target="_blank">{{ $t('home.problem.appreciation_link.tag') }}</c-link>
            </template>
          </i18n>
        </v-col>
      </v-row>
    </c-chapter>

    <!-- *** The Why -->
    <c-chapter id="blog-reasons" :title="$t('home.why_bligson.title')" title-center>

      <blockquote class="text-center transparent pt-0">
        {{ $t('company.mission.compelling') }}
      </blockquote>

      <v-row>
        <v-col
          v-for="i in 4"
          :id="`why_${i}`"
          :key="i"
          cols="12"
          sm="6"
          md="3"
          class="cursor-pointer"
          @click="toggleMore(i)"
        >
          <v-card style="height: 100%;">
            <h4 class="center bold foreground-2--text why-header py-1 px-2 d-flex align-center" style="height: 60px;">
              {{ $t(`home.why_bligson.${i}.title`) }}
            </h4>

            <v-img
              v-if="moreImage[i] && moreImage[i].src"
              :src="moreImage[i].src"
              :alt="moreImage[i].alt || 'By Bligson'"
              :aspect-ratio="moreImage[i].aspectRatio || 1"
              :max-height="130"
              contain
              class="my-1 transform-effect"
              :class="{'transform-mirror': more[i]}"
            />

            <!-- description -->
            <v-card-text>
              <p>
                <i18n
                  tag="span"
                  :path="`home.why_bligson.${i}.description`"
                  class="mt-1 mt-md-2 paragraph"
                >
                  <!-- 1 -->
                  <template v-slot:bot_link>
                    <c-link to="/service/bligsy">{{ $t(`home.why_bligson.${i}.bot_link.tag`) }}</c-link>
                  </template>
                  <template v-slot:experience_link>
                    <c-link to="/service/bligsy">{{ $t(`home.why_bligson.${i}.experience_link.tag`) }}</c-link>
                  </template>

                  <!-- 2 -->
                  <template v-slot:stories_link>
                    <c-link to="/service/framework#stories">{{ $t(`home.why_bligson.${i}.stories_link.tag`) }}</c-link>
                  </template>

                  <!-- 3 -->
                  <template v-slot:valued_link>
                    <c-link to="/insights/showcase">{{ $t(`home.why_bligson.${i}.valued_link.tag`) }}</c-link>
                  </template>

                  <!-- 4 -->
                  <template v-slot:analytics_link>
                    <c-link to="/service/framework#analytics">{{ $t(`home.why_bligson.${i}.analytics_link.tag`) }}</c-link>
                  </template>
                </i18n>
              </p>

              <a v-show="!more[i]" :class="{'d-none': $vuetify.breakpoint.smAndUp}">
                {{ $t('def.read') }}
              </a>

              <v-expand-transition>
                <p v-show="more[i]" class=" pa-2 mx-n2" @click.stop>
                  <i18n :path="`home.why_bligson.${i}.more`" tag="span">
                    <!-- 1 -->
                    <template v-slot:coach_link>
                      <c-link to="/service/coaching">{{ $t(`home.why_bligson.${i}.coach_link.tag`) }}</c-link>
                    </template>
                    <template v-slot:app_link>
                      <c-link to="/solution">{{ $t(`home.why_bligson.${i}.app_link.tag`) }}</c-link>
                    </template>
                    <template v-slot:bligsy_link>
                      <c-link to="/service/bligsy">{{ $t(`home.why_bligson.${i}.bligsy_link.tag`) }}</c-link>
                    </template>

                    <!-- 2 -->
                    <template v-slot:pt_link>
                      <c-link to="/service/framework#pipeline-templates">{{ $t(`home.why_bligson.${i}.pt_link.tag`) }}</c-link>
                    </template>
                    <template v-slot:campaigns_link>
                      <c-link to="/service/framework#campaigns">{{ $t(`home.why_bligson.${i}.campaigns_link.tag`) }}</c-link>
                    </template>
                    <template v-slot:harvard_link>
                      <c-link :href="$t(`home.why_bligson.${i}.harvard_link.url`)" target="_blank">{{ $t(`home.why_bligson.${i}.harvard_link.tag`) }}</c-link>
                    </template>

                    <!-- 3 -->
                    <template v-slot:attraction_link>
                      <c-link :href="$t(`home.why_bligson.${i}.attraction_link.url`)" target="_blank">{{ $t(`home.why_bligson.${i}.attraction_link.tag`) }}</c-link>
                    </template>

                    <!-- 4 -->
                    <!-- Bligsy, see above -->
                    <template v-slot:data_link>
                      <c-link to="/service/framework#analytics">{{ $t(`home.why_bligson.${i}.data_link.tag`) }}</c-link>
                    </template>

                  </i18n>
                </p>

              </v-expand-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="!isAllMore" class="d-none d-sm-flex justify-center mt-2">
        <v-btn color="primary" text @click="setAllMore()">{{ $t("def.read") }}</v-btn>
      </div>
    </c-chapter>
  </div>
</template>

<script>

import { mdiMore } from '@mdi/js'

export default {
  data: () => ({
    more: [undefined, false, false, false, false],
    moreImage: [
      undefined, // [0] is not used
      {
        src: require('@/assets/product/Bligsy-happy-bot-v07.svg'),
        alt: 'Bligsy - Bligson Experience Collecting Bot',
        aspectRatio: 1
      },
      {
        src: require('@/assets/product/publications-v01.svg'),
        alt: 'Bligsy - Social Media Promotion',
        aspectRatio: 1
      },
      {
        src: require('@/assets/silhouettes/employer-branding-yoga-zen-happy.svg'),
        alt: 'Employer Branding - Zen',
        aspectRatio: 675 / 600
      },
      {
        src: require('@/assets/product/analytics-pulse-v01.svg'),
        alt: 'Employee Pulse',
        aspectRatio: 1
      }
    ],
    mdiMore,
    // show *next* year in November and December, otherwise this year
    year: new Date().getFullYear() + (new Date().getMonth() > 10 ? 1 : 0)
  }),
  computed: {
    isAllMore () {
      return this.more[1] && this.more[2] && this.more[3]
    }
  },
  methods: {
    toggleMore (i) {
      // we need $set for reactivity on an array in Vue
      this.$set(this.more, i, !this.more[i])
    },
    setAllMore () {
      this.more = [undefined, true, true, true, true]
    }
  }

}
</script>
