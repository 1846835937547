  <!-- Show a card with a post -->

<template>
  <v-card
    :class="compact ? 'mt-0 pa-1' : 'mt-7'"
    :height="compact ? 210: 470"
    :to="`/post/${post._id}`"
    :ripple="false"
  >
    <!-- Note: the "mt-7" is to make room for a "featured" star -->

    <v-fab-transition v-if="showFeaturedStar && post.featured">
      <v-btn color="secondary" x-small absolute top right fab><v-icon>mdi-star</v-icon></v-btn>
    </v-fab-transition>

    <v-img
      class="white--text align-end grey lighten-3"
      height="200px"
      :src="post.featureImageSrc || ''"
      gradient="0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 80%"
    >
      <v-card-title
        class="semi-transparent-dark pb-2"
        :class="compact ? 'subtitle-2' : 'blog-title title '"
      >
        {{ post.title }}
      </v-card-title>
    </v-img>

    <template v-if="!compact">
      <v-card-subtitle class="primary--text overline pb-2 d-flex justify-space-between" style="line-height: 1.5em;">
        {{ authorName }}
        <blog-post-date class="ml-1" :post-date="post.startAt" />
      </v-card-subtitle>

      <v-card-text class="text--primary text-left" style="height: 175px;">
        {{ post.excerpt || '' }}
      </v-card-text>

      <v-card-actions class="align-end primary--text">
        <div class="overline pl-2 pb-2">
          {{ readingMinutes }} {{ $t('blog.reading_time', {'s': readingMinutes===1 ? '':'s'}) }}
        </div>
      <!-- <v-spacer /> -->

      <!-- <v-btn class="pb-2" color="primary" text>{{ $t("def.read") }}</v-btn> -->
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import Log from '@/components/_lib/log' // eslint-disable-line no-unused-vars

import BlogPostDate from '@/components/_lib/BlogPostDate.vue'

export default {
  components: {
    BlogPostDate
  },
  props: {
    showFeaturedStar: {
      type: Boolean,
      default: false
    },
    post: {
      type: Object,
      default: () => {}
    },
    // Do not show the pane with date and abstract / intro
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    authorName () {
      return this.post.authors && this.post.authors.length > 0 ? this.post.authors[0].displayName : ''
    },
    documentLength () {
      return this.post.bodyHtml ? this.post.bodyHtml.length : 0
    },
    readingMinutes () {
      return Math.ceil(this.documentLength / (this.$config.EDIT_SETTINGS.charactersPerWord * this.$config.EDIT_SETTINGS.wordsPerMinute))
    }
  }
}
</script>

<style lang="scss" scoped>
.blog-title {
  word-break:normal;
  hyphens:auto;
  line-height: 1.5rem;
}
.semi-transparent-dark {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0) 80%
  );
}
.blog-post-content {
  height: 120px !important;
  overflow: hidden;
  padding-bottom: 50px;
}
</style>
