<template>
  <v-card color="background-1 d-flex py-2 py-sm-4 align-center justify-center" flat>
    <c-chapter
      id="service-provider"
      style="max-width:1920px;"
      split-col
      align-center
    >
      <template v-slot:intro>
        <!-- the mobile story screenshot -->
        <div class="d-flex justify-center my-6">
          <nuxt-link to="/service/framework#0_achievements#stories">
            <v-card :width="$vuetify.breakpoint.smAndUp ? 260 : 200" elevation="12">
              <v-img
                alt="Employee Achievement Stories "
                :src=" require('@/assets/why/stories_mobile.png')"
                :aspect-ratio="0.5859"
                position="top"
                contain
                content-class="contain-v-img"
              />
            </v-card>
          </nuxt-link>
        </div>
      </template>

      <h2 class="display-1 text-center mt-4 mb-2 mb-sm-4 font-weight-light info--text" v-html="$t('home.why.title')" />

      <staff-pane
        avatar-folder="workers"
        avatars-center
        :avatar-size="$vuetify.breakpoint.lgAndUp ? 50 : 35"
        hide-stepper
        :step-size="7"
        :max-nr="7"
        style="overflow: hidden;"
      />

      <blockquote class="text-center transparent title font-weight-light mt-0 px-0">
        {{ $t('company.vision.primary') }}
      </blockquote>

    </c-chapter>
  </v-card>
</template>

<script>
import StaffPane from '@/components/_lib/_StaffPane.vue'

export default {
  components: {
    StaffPane
  }
}
</script>
