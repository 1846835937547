<template>
  <span>
    {{ blogPostDate }}
  </span>
</template>

<script>
export default {
  props: {
    postDate: {
      type: String,
      default: () => ((new Date()).toISOString())
    },
    short: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    blogPostDate () {
      const options = {
        year: 'numeric',
        month: this.short ? 'short' : 'long',
        day: 'numeric'
      }
      const createdDate = new Date(this.postDate)
      let locale = this.$i18n.locale
      locale = locale + '-' + locale.toUpperCase() // 'nl' -> 'nl-NL', 'en' -> 'en-EN'
      return createdDate.toLocaleDateString(locale, options).replace(' ', ' ').replace(' ', ' ') // replace space with non-breaking space
    }
  }

}
</script>
