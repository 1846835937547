<template>
  <v-row>
    <v-col v-if="!avatarsLeft && !avatarsCenter" cols="12" md="6" class="d-flex align-center">
      <slot />
    </v-col>

    <v-col cols="12" :md="avatarsCenter ? 12 : 6">
      <p v-if="intro" class="mt-2 mb-6">{{ intro }}</p>

      <v-row>
        <v-col cols="12" class="d-inline-flex justify-center">

          <v-list-item-avatar v-for="i in stepSize" :key="i" :size="avatarSize">
            <v-tooltip v-if=" (i + offset) <= maxNr" top>
              <template v-slot:activator="{ on }">
                <v-img
                  :alt="names[i + offset]"
                  :src="require(`@/assets/staff/${avatarFolder}/${i + offset}${avatarType}`)"
                  v-on="on"
                />
              </template>
              <span>{{ names[i + offset] }}</span>
            </v-tooltip>
          </v-list-item-avatar>
        </v-col>

        <v-col v-if="!hideStepper" cols="12">
          <v-pagination
            v-model="page"
            :length="Math.ceil(maxNr / stepSize)"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="avatarsLeft && !avatarsCenter" cols="12" md="6">
      <slot />
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    intro: { type: String, default: '' },
    avatarFolder: { type: String, default: 'workers' },
    avatarType: { type: String, default: '.jpg' },
    avatarsLeft: { type: Boolean, default: false },
    avatarsCenter: { type: Boolean, default: false },
    hideStepper: { type: Boolean, default: false },
    stepSize: { type: Number, default: 4 },
    maxNr: { type: Number, default: 4 },
    avatarSize: { type: Number, default: 80 }
  },
  data: () => ({
    page: 1,
    names: [
      '', // 0 is not used
      'Miranda von S.', // 1
      'Jacques ter G.', // 2
      'Sarah J.K.', // 3
      'Peter S.', // 4
      'Yvonne L.', // 5
      'Karin M.', // 6
      'Annemiek', // 7
      'Anna K.', // 8
      'Sandra S.', // 9
      'Dana', // 10
      'Jean A.', // 11
      'Jean F.', // 12
      'Emma Ah.', // 13
      'Jennifer O.', // 14
      'Tanya J.' // 15
    ]
  }),
  computed: {
    offset () {
      return this.stepSize * (this.page - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item__avatar {
  margin-right: .2em !important;
  margin-left: .2em !important;
}
</style>
