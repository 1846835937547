<template>
  <!-- Text at the very top of home page; main title -->

  <v-row id="all-pay-off" dense class="foreground-2--text py-12">
    <v-col class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
      <c-spacer />
      <c-spacer />

      <h1 class="bold mt-3 mt-sm-4 mb-3 mb-sm-4 foreground-2--text" v-html="$t('home.title')" />

      <v-icon class="my-2" :size="size" :color="colorHeart">mdi-eye-check-outline</v-icon>

      <h3 class="bold mt-1 primary--text">{{ $t('home.description.1') }}</h3>

      <!-- <div class="d-flex align-end justify-center mr-n8"> -->
      <h3 class="mt-4 bold info--text">{{ $t('home.description.2') }}</h3>
      <!-- pb-6  <v-icon class="ml-4" :size="size + 6" :color="color">mdi-arrow-u-left-bottom</v-icon> -->
      <!-- </div> -->

      <v-icon class="my-3" :size="size" :color="colorHeart">mdi-account-heart-outline</v-icon>

      <!-- <div class="mt-3 d-flex align-start justify-center"> -->
      <!-- <v-icon class="mt-n4 ml-n4" :size="size +6" :color="color">mdi-arrow-u-right-bottom</v-icon> -->
      <h2 class="bold foreground-2--text">{{ $t('home.description.3') }}</h2>
      <!-- </div> -->

      <c-spacer />
    </v-col>
  </v-row>
</template>

<script>

export default {
  data: () => {
    return {
      parImg: undefined,
      // size: vm.$vuetify.breakpoint.mdAndUp ? 35 : 20,
      color: 'background-3',
      colorHeart: 'foreground-2'
    }
  },
  computed: {
    size () {
      return this.$vuetify.breakpoint.mdAndUp ? 36 : 28
    }
  }
}
</script>
