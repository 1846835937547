<!-- Fetch pages or posts from Bligman: async - see also

Examples:
  <fetch-bligman v-model="posts" posts debug api-filter="&$limit=3" />
  <fetch-bligman v-model="posts" posts api-filter="&tag=strategy&tag=sales" />

  <fetch-bligman v-model="posts" api-filter="&slug=terms-conditions" />
-->

<template>

  <div>
    <div v-if="$fetchState.pending" class="d-flex align-center justify-center" style="height: 200px; width: 100%">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </div>

    <v-card
      v-else-if="$fetchState.error || response.code >= 400 "
      color="warning"
      tile
      class="mt-10 mx-auto pa-4"
    >
      <p v-if="debug" class="caption">{{ response }}</p>
      <p v-if="debug" class="overline">fetchState: {{ $fetchState }}</p>

      <p>{{ $t('def.sorry') }} {{ $t('def.error') }} {{ $t('def.come_back') }}</p>
    </v-card>
  </div>
</template>

<script>
import Log from '@/components/_lib/log' // eslint-disable-line

export default {
  props: {
    // the loaded posts array
    value: {
      type: Array,
      default: () => []
    },
    // appended to the url
    apiFilter: {
      type: String,
      default: '' // examples: '&$limit=20'
    },
    // what end point to use? v1/pages or v1/posts
    posts: {
      type: Boolean,
      default: false // default it is page
    },
    debug: {
      type: Boolean,
      default: false
    }
  },
  async fetch () {
    this.response = await fetch(this.pageUrl).then(res => res.json())
    // this.$emit('input', this.response.data)
    Log.i(`[api] fetch Bligman ${this.pageUrl}, ${this.posts ? 'posts' : 'pages'} loaded: ${this.response.total}`)
    // Log.i(this.response)
    if (this.response && this.response.total >= 1 && this.response.data && this.response.data.length > 0) {
      if (this.response.code && this.response.code > 200) {
        Log.w('[]api error', this.response)
      } else {
        this.$emit('input', this.response.data)
      }
    }
  },
  data () {
    return {
      response: {}
    }
  },
  computed: {
    pageUrl () {
      const sortFilter = '&$sort[startAt]=-1'
      if (this.posts) {
        return `${this.$config.BLIGSON_API.posts.url}${this.apiFilter}${sortFilter}`
      } else {
        return `${this.$config.BLIGSON_API.pages.url}${this.apiFilter}${sortFilter}`
      }
    },
    stateOk () {
      return !this.$fetchState.pending && !this.$fetchState.error !== null
    }
  },
  fetchOnServer: false, // if true, does not work
  fetchDelay: 0, // in ms; default 200
  fetchKey: 'fetch-page'
}
</script>
