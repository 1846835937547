<template>
  <div id="home">
    <!-- :srcset="require('@/assets/home/employee-background.jpg') +' 2000w,' +
        require('@/assets/home/employee-background-md.jpg') +' 1000w'" -->

    <all-pay-off />

    <bligson-parallax />

    <v-img :src="backgroundImg" class="limit-height">
      <c-spacer />
      <emp-brn-reasons />
      <c-spacer />
    </v-img>

    <tell-their-story />

    <c-spacer half />
    <stepper />
    <c-spacer half />

    <v-img :src="backgroundImg" class="limit-height">

      <testimonial />

      <c-spacer />
      <whats-new />
    </v-img>
  </div>
</template>

<script>
import AllPayOff from '@/components/home/AllPayOff.vue'
import BligsonParallax from '@/components/home/BligsonParallax.vue'
import EmpBrnReasons from '@/components/home/EmpBrnReasons'
// import BligmanGallery from '@/components/home/BligmanGallery'
import Stepper from '@/components/home/Stepper'
// import ElevatorPitch from '@/components/home/ElevatorPitch.vue'
import TellTheirStory from '@/components/home/TellTheirStory.vue'
import Testimonial from '@/components/home/Testimonial.vue'

import WhatsNew from '@/components/home/WhatsNew.vue'

export default {
  name: 'Index',
  components: {
    AllPayOff,
    BligsonParallax,
    EmpBrnReasons,
    Stepper,
    // BligmanGallery,
    // ElevatorPitch,
    TellTheirStory,
    Testimonial,
    WhatsNew
  },
  data () {
    return {
      backgroundImg: undefined
    }
  },
  mounted () {
    this.backgroundImg = require('@/assets/home/employee-background-sm.jpg')
  }
}
</script>

<style lang="scss" >
// Needed to prevent background images taking too much space
.limit-height > .v-responsive__sizer {
  padding-bottom: 0 !important;
}
</style>
